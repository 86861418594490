import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { RoomCreateDto } from '@floori/models';
import { BaseRoomCategory } from '../rooms';

export const FLOORI_ROOMS_API = new InjectionToken('FLOORI_ROOMS_API');

export interface RoomsApiProvider {
    createVisitorRoom(
        image: File,
        visitorId: string,
        progressCallback?: (progress: number) => void,
    ): Observable<RoomCreateDto>;

    fetchBaseRooms(visitorId?: string): Observable<BaseRoomCategory[]>;
}
