import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { FLOORI_TRANSLATOR, flooriHelperClasses } from '@floori-web/constants';
import {
    DialogProvider,
    DraftModeProvider,
    FLOORI_DIALOG_SERVICE,
    FLOORI_NAVIGATION,
    FLOORI_STORAGE_PROVIDER,
    LocalStorageKeys,
    NavigationProvider,
    SnackbarDialogData,
    StorageProvider,
    TranslationsProvider,
} from '@floori-web/models';
import { FlooriDialogRef } from '@floori-web/ui/dialog/src';
import { PopupPosition } from '@floori/models';
import {
    filter,
    switchMap,
    of,
    tap,
    BehaviorSubject,
    distinctUntilChanged,
    EMPTY,
    from,
    Observable,
} from 'rxjs';

@Injectable()
export class DraftModeService implements DraftModeProvider {
    private renderer: Renderer2;
    private draft: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private draftPopupRef?: FlooriDialogRef;
    isDraftMode$ = this.draft.asObservable();

    constructor(
        private readonly router: Router,
        private readonly rendererFactory: RendererFactory2,
        @Inject(FLOORI_STORAGE_PROVIDER) private readonly storageProvider: StorageProvider,
        @Inject(FLOORI_DIALOG_SERVICE) private readonly dialogProvider: DialogProvider,
        @Inject(FLOORI_TRANSLATOR) private readonly translationsProvider: TranslationsProvider,
        @Inject(FLOORI_NAVIGATION) private readonly navigationProvider: NavigationProvider,
    ) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    init(): void {
        this.setDraftMode();
        this.watchDraftMode();
    }

    toggleDraftMode(): void {
        this.draft.next(!this.draft.getValue());

        if (this.draft.getValue()) {
            this.navigationProvider.appendQueryParam('draft', 'true');
        } else {
            this.navigationProvider.appendQueryParam('draft', null);
        }
    }

    private setDraftMode(): void {
        this.router.events.subscribe(e => {
            if (e instanceof ActivationEnd) {
                this.draft.next(e.snapshot.queryParamMap.get('draft') === 'true');
            }
        });
    }

    private watchDraftMode(): void {
        this.draft
            .asObservable()
            .pipe(
                distinctUntilChanged(),
                tap(draft => {
                    if (draft) {
                        this.enableDraft();
                        return;
                    }
                    this.disableDraft();
                }),
            )
            .subscribe();
    }

    private disableDraft(): void {
        this.renderer.removeClass(document.body, 'draft');
        this.draftPopupRef?.close();
    }

    private enableDraft(): void {
        this.renderer.addClass(document.body, 'draft');
        this.storageProvider
            .get<boolean>(LocalStorageKeys.draftPopupClosed)
            .pipe(
                filter(closed => !closed),
                switchMap(() => this.openDraftModePopup()),
                switchMap(closed =>
                    closed
                        ? this.storageProvider.set<boolean>(LocalStorageKeys.draftPopupClosed, true)
                        : of(undefined),
                ),
            )
            .subscribe();
    }

    private openDraftModePopup(): Observable<unknown> {
        return from(import('@floori-web/ui/snackbar')).pipe(
            // This is component name so cannot apply camelCase to it
            // eslint-disable-next-line @typescript-eslint/naming-convention
            switchMap(({ SnackbarComponent }) => {
                if (this.draftPopupRef) {
                    return EMPTY;
                }

                this.draftPopupRef = this.dialogProvider.open<unknown, SnackbarDialogData>(
                    SnackbarComponent,
                    {
                        containerClass: [
                            flooriHelperClasses['snackbar'],
                            flooriHelperClasses['stretchWrapper'],
                        ],
                        panelClass: flooriHelperClasses['transparentOverlay'],
                        hasBackdrop: false,
                        closeOnDestroy: true,
                        data: {
                            text: this.translationsProvider.translate('draftMode.snackbarLabel'),
                            position: PopupPosition.top,
                            showCloseButton: true,
                        },
                    },
                ) as FlooriDialogRef;

                return this.draftPopupRef?.beforeClosed();
            }),
        );
    }
}
