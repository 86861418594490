import { DimensionUnit } from '@floori/models';

export class UnitsConverter {
    private readonly unitMap: Record<DimensionUnit, number> = {
        [DimensionUnit.mm]: 1,
        [DimensionUnit.cm]: 10,
        [DimensionUnit.meter]: 1000,
        [DimensionUnit.inch]: 1,
        [DimensionUnit.ft]: 12,
    };

    convertFromMMOrINCHToGivenUnit(value: number, inputUnit: DimensionUnit): number {
        const multiplier = this.unitMap[inputUnit];
        return value ? Number((value / multiplier).toFixed(4)) : 0;
    }
}
