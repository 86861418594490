export type FlooriRoutes = 'ar' | 'calculator' | 'room' | 'rooms' | 'preview' | 'notFound';

export const flooriRoutes: Record<FlooriRoutes, string> = {
    ar: 'ar',
    calculator: 'calculator',
    rooms: '',
    room: 'room/:uid',
    preview: 'preview',
    notFound: 'not-found',
} as const;
