import { FlooriFont, FlooriFontFamily } from '@floori/models';

export const flooriFonts: Record<FlooriFontFamily, FlooriFont> = {
    [FlooriFontFamily.inter]: {
        family: FlooriFontFamily.inter,
        url: 'https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap',
    },
    [FlooriFontFamily.roboto]: {
        family: FlooriFontFamily.roboto,
        url: 'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap',
    },
    [FlooriFontFamily.sans]: {
        family: FlooriFontFamily.sans,
        url: '',
    },
    [FlooriFontFamily.avenir]: {
        family: FlooriFontFamily.avenir,
        url: 'https://d2buk70psd9jzv.cloudfront.net/assets/fonts/AvenirNextLTPro-Regular.otf',
    },
};
