// Selectors should be used as atributes so need to be inside []
/* eslint-disable @angular-eslint/directive-selector */
import {
    Directive,
    ElementRef,
    HostBinding,
    HostListener,
    Inject,
    Input,
    OnInit,
    Optional,
} from '@angular/core';
import { DialogProvider, FLOORI_DIALOG_SERVICE } from '@floori-web/models';
import { FlooriDialogRef } from '../common';

@Directive({
    selector: '[floori-dialog-close]',
    exportAs: 'flooriDialogClose',
})
export class FlooriDialogCloseDirective implements OnInit {
    @Input('floori-dialog-close') dialogResult?: unknown;

    constructor(
        @Inject(FLOORI_DIALOG_SERVICE) private readonly dialog: DialogProvider,
        private readonly elRef: ElementRef,
        @Optional() public dialogRef: FlooriDialogRef<unknown>,
    ) {}

    ngOnInit(): void {
        if (this.dialogRef) {
            return;
        }

        this.dialogRef = getClosestDialog(
            this.elRef,
            this.dialog.openDialogs,
        ) as FlooriDialogRef<unknown>;
    }

    @HostListener('click', ['$event.target'])
    buttonClick(): void {
        this.dialogRef?.close(this.dialogResult);
    }
}

@Directive({
    selector: '[floori-dialog-title]',
    exportAs: 'flooriDialogTitle',
})
export class FlooriDialogTitleDirective {
    @Input() id?: string;

    @HostBinding('class')
    get titleCls(): string | string[] {
        return ['floori-dialog-title'];
    }
}

@Directive({
    selector: '[floori-dialog-content]',
    exportAs: 'flooriDialogContent',
})
export class FlooriDialogContentDirective {
    @HostBinding('class')
    get contentCls(): string | string[] {
        return ['floori-dialog-content'];
    }
}

@Directive({
    selector: '[floori-dialog-actions]',
    exportAs: 'flooriDialogActions',
})
export class FlooriDialogActionsDirective {
    @HostBinding('class')
    get contentCls(): string | string[] {
        return ['floori-dialog-actions'];
    }
}

// Cannot use const or class instead of function
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
function getClosestDialog(
    element: ElementRef<HTMLElement>,
    openDialogs: FlooriDialogRef<unknown>[],
): FlooriDialogRef<unknown, unknown> | null | undefined {
    let parent: HTMLElement | null = element.nativeElement.parentElement;

    while (parent && !parent.classList.contains('floori-dialog-container')) {
        parent = parent.parentElement;
    }

    return parent ? openDialogs.find(dialog => dialog.id === parent?.id) : null;
}
