import { FlooriThemes } from '@floori/models';

export const flooriThemes: Record<FlooriThemes, string> = {
    [FlooriThemes.default]: 'floori',
} as const;

export const flooriHelperClasses: Record<string, string> = {
    noClick: 'floori-no-pointer-events',
    darkOverlay: 'floori-dark-overlay',
    fullOverlay: 'floori-overlay-full',
    centerOverlay: 'floori-overlay-center',
    containOverlay: 'floori-overlay-contain',
    stretchWrapper: 'stretch',
    transparentOverlay: 'floori-overlay-transparent',
    sidePanel: 'floori-side-panel',
    attachBottom: 'floori-dialog-bottom-mobile',
    snackbar: 'floori-snackbar',
} as const;
