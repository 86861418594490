export enum ProductCategory {
    floors = 'floors',
    rugs = 'rugs',
    walls = 'walls',
    epoxy = 'epoxy',
    countertops = 'countertops',
    trims = 'trims',
    cabinets = 'cabinets',
    doors = 'doors',
    doorFrames = 'door_frames',
    stairs = 'stairs',
    ceilings = 'ceilings',
}
