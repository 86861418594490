import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FLOORI_NAVIGATION } from '@floori-web/models';
import { NavigationService } from './navigation/navigation.service';

@NgModule({
    imports: [CommonModule, RouterModule],
    providers: [
        {
            provide: FLOORI_NAVIGATION,
            useClass: NavigationService,
        },
    ],
})
export class NavigationModule {}
