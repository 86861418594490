import { CompanyConfig } from '@floori/models';

export const bonaAdditions = (config: CompanyConfig): Partial<CompanyConfig> => ({
    themeCssVars: {
        ...config.themeCssVars,
        primary: '#00205C',
        primary100: '#EEF2FD',
    },
    dynamicFeatures: {
        translationScope: 'bona',
        hideProductDetails: true,
        roomsMessage: 'features.rooms.welcomeMessage',
        roomMessage: 'features.room.welcomeMessage',
        roomProductMessage: 'features.room.productMessage',
    },
});
