export enum ProductPlacementOptions {
    floors = 'floors',
    rugs = 'rugs',
    countertops = 'countertops',
    walls = 'walls',
    trims = 'trims',
    cabinets = 'cabinets',
    doors = 'doors',
    doorFrames = 'doorFrames',
    stairs = 'stairs',
    ceilings = 'ceilings',
}
