import { EventEmitter, InjectionToken } from '@angular/core';
import { EstimationDto, HashMap, PlaneXYPosition, ProductExtended, RoomDto } from '@floori/models';
import { Observable } from 'rxjs';
import {
    ParsedProductModel,
    Visualization,
    VisualizationData,
} from '@floori-web/visualization-engine';
import { Size } from '@floori-web/visualization-models';
import { HotpointProductPair } from '../products';
import { ZoomType } from './zoom-type';

export const FLOORI_VISUALIZATION_PROVIDER = new InjectionToken('FLOORI_VISUALIZATION_PROVIDER');

export interface VisualizationProvider {
    visualizationLoaded$: Observable<Visualization | null>;
    zoomMoved: EventEmitter<void>;
    isZoomed: boolean;
    isZoomed$: Observable<boolean>;
    zoomDirection$: Observable<ZoomType | null>;
    imageSize$: Observable<Size | undefined>;
    visualizationData: VisualizationData | undefined;

    loadByElement(
        data: EstimationDto,
        roomName: RoomDto | undefined,
        element?: HTMLCanvasElement | object,
    ): Observable<boolean>;

    loadProduct(
        product: ProductExtended,
        index: number,
        hotpoints: HashMap<HotpointProductPair>,
        position?: PlaneXYPosition,
    ): Observable<boolean>;

    changeProduct(product: ProductExtended, hotpoints: HashMap<HotpointProductPair>): void;

    parseProductModel(
        product: ProductExtended,
        variantIndex?: number,
    ): Observable<ParsedProductModel | null>;

    gltfExporterParse(sceneNode: ParsedProductModel): Observable<ArrayBuffer>;

    resize(): void;

    zoom(zoomIn: boolean): void;

    updateHotpoints(hotpoints: HashMap<HotpointProductPair>): void;
}
