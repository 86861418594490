import { EventType } from '@angular/router';
import { TypedAction } from '@ngrx/store/src/models';
import { RouterNavigationEvent } from '@floori-web/models';
import { routerActions } from '@floori-web/store';

export const routerActionsMap: ReadonlyMap<
    EventType,
    (event: RouterNavigationEvent) => TypedAction<string>
> = new Map<EventType, (event: RouterNavigationEvent) => TypedAction<string>>([
    [
        EventType.NavigationStart,
        (event: RouterNavigationEvent): TypedAction<string> =>
            routerActions.routeNavigationStart(event),
    ],
    [
        EventType.NavigationEnd,
        (event: RouterNavigationEvent): TypedAction<string> =>
            routerActions.routeNavigationEnd(event),
    ],
    [
        EventType.NavigationError,
        (event: RouterNavigationEvent): TypedAction<string> =>
            routerActions.routeNavigationError(event),
    ],
    [
        EventType.NavigationCancel,
        (event: RouterNavigationEvent): TypedAction<string> =>
            routerActions.routeNavigationError(event),
    ],
]);
