import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input,
    ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlooriButtonComponent } from '@floori-web/ui/button';
import { FlooriDialogModule, FlooriDialogRef } from '@floori-web/ui/dialog';
import { FlooriIconComponent } from '@floori-web/ui/icon';

@Component({
    // Cannot change selector because this component is used like directive
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '[floori-dialog-header]',
    standalone: true,
    imports: [CommonModule, FlooriButtonComponent, FlooriDialogModule, FlooriIconComponent],
    templateUrl: './dialog-header.component.html',
    styleUrls: ['./dialog-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class DialogHeaderComponent<T = unknown> {
    @Input() cls: string | string[] = ['floori-dialog-header'];

    @HostBinding('class')
    get hostCls(): string | string[] {
        return this.cls;
    }

    constructor(private readonly dialogRef: FlooriDialogRef<T>) {}

    close(): void {
        this.dialogRef.close();
    }
}
