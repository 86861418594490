import { Observable } from 'rxjs';
import {
    CompanyConfig,
    ConfigState,
    DynamicFeatures,
    FlooriPages,
    Languages,
    Layouts,
} from '@floori/models';
import { InjectionToken } from '@angular/core';

export const FLOORI_CONFIG_PROVIDER = new InjectionToken('FLOORI_CONFIG_PROVIDER');

export interface ConfigProvider {
    readonly companyConfig$: Observable<ConfigState>;
    readonly dynamicFeatures$: Observable<DynamicFeatures | undefined>;
    readonly companyConfigRaw$: Observable<CompanyConfig | undefined>;
    readonly pagesLayouts$: Observable<Record<FlooriPages, Layouts | null> | undefined>;
    readonly companyLogo$: Observable<string>;

    initConfig(companyConfig: CompanyConfig): void;

    changeLang(code: Languages): void;
}
