import { ProductRGBAColor } from '@floori/models';

export class ColorsConverter {
    static convertHexToRGBA(hex: string): ProductRGBAColor {
        const hexColor = hex.startsWith('#') ? hex.slice(1) : hex;

        return {
            r: parseInt(hexColor.slice(0, 2), 16),
            g: parseInt(hexColor.slice(2, 4), 16),
            b: parseInt(hexColor.slice(4, 6), 16),
            a: 1,
        };
    }

    static convertRGBAToHex(rgba: ProductRGBAColor): string {
        const { r, g, b } = rgba;
        const rgb = [r, g, b].map(value => value.toString(16).padStart(2, '0')).join('');
        return `#${rgb}`;
    }
}
