import { ErrorState } from '@floori-web/models';
import { createReducer, on } from '@ngrx/store';
import { errorActions } from '@floori-web/store';

export const ERRORS_KEY = 'flooriError';

const initialState: ErrorState = {
    show: false,
    message: '',
};

const setError = on(
    errorActions.setError,
    (state: ErrorState, { show, message }) =>
        ({
            ...state,
            show,
            message,
        } as ErrorState),
);

export const errorsReducer = createReducer(initialState, setError);
