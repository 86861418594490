import { Injectable, Type } from '@angular/core';
import { Layouts } from '@floori/models';
import { LayoutsLoader } from '@floori-web/models';
import { availableLayouts } from './available-layouts';

@Injectable()
export class LayoutService implements LayoutsLoader {
    private readonly layouts = { ...availableLayouts };
    private readonly loadedLayouts: { [key in Layouts]?: Type<unknown> } = {};

    async loadLayout<T = unknown>(layout: Layouts): Promise<Type<T>> {
        if (this.loadedLayouts[layout]) {
            return this.loadedLayouts[layout] as Type<T>;
        }
        const layoutLoader = this.layouts[layout];
        if (!layoutLoader) {
            return Promise.reject();
        }
        const layoutLoaded: any = await layoutLoader();
        const layoutName = this.formatComponentName(layout);
        return layoutLoaded[layoutName] as Type<T>;
    }

    private formatComponentName(componentName: string): string {
        const suffix = 'LayoutComponent';
        const name = `${componentName?.charAt(0).toUpperCase()}${componentName?.slice(1)}`;
        return name?.endsWith(suffix) ? name : `${name}${suffix}`;
    }
}
