import { inject, Injectable, OnDestroy } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { DynamicProduct, FLOORI_NAVIGATION, NavigationProvider } from '../../models/src';
import {
    DimensionUnit,
    ProductPlacementOptions,
    ProductTileSize,
    ProductType,
} from '../../../../shared/models/src';

@Injectable()
export abstract class BaseEffects implements OnDestroy {
    protected readonly noDispatch = of(null) as never;
    protected readonly actions$ = inject(Actions);
    protected readonly router = inject(Router);
    protected readonly store = inject(Store);
    protected readonly navigation = inject<NavigationProvider>(FLOORI_NAVIGATION);
    protected destroyed$ = new Subject();

    protected get dynamicProductParams(): DynamicProduct | null {
        const paramsMap = this.navigation.dynamicProductParams();
        if (!paramsMap) {
            return null;
        }
        const [width, height, url] = paramsMap;
        return {
            dynamic: true,
            id: '',
            name: { default: '' },
            sku: '',
            width: +width,
            height: +height,
            type: ProductType.rug,
            image: {
                large: url,
            },
            combined: [{ image: { large: url }, countX: 1, countY: 1 }],
            tileOptions: [
                {
                    prices: [],
                    isDefault: true,
                    width,
                    height,
                    url,
                    unit: DimensionUnit.meter,
                } as unknown as ProductTileSize,
            ],
            groupedTileOptions: [],
            placementOptions: [ProductPlacementOptions.floors, ProductPlacementOptions.rugs],
        };
    }

    ngOnDestroy(): void {
        this.destroyed$.next(void 0);
        this.destroyed$.complete();
    }
}
