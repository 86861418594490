import { ModuleWithProviders, NgModule } from '@angular/core';
import { FLOORI_TRACKING_PROVIDER } from '@floori-web/constants';
import { TrackingModuleOptions } from './models';
import { baseProviders } from './providers.list';
import { TrackingService } from './tracking.service';

@NgModule({
    imports: [],
    providers: [],
})
export class TrackingModule {
    static forRoot(config?: TrackingModuleOptions): ModuleWithProviders<TrackingModule> {
        const providers = config?.providers || [...baseProviders];
        providers.push({
            provide: FLOORI_TRACKING_PROVIDER,
            useClass: TrackingService,
        });
        return {
            ngModule: TrackingModule,
            providers,
        };
    }
}
