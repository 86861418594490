import { InjectionToken } from '@angular/core';
import { GtmTrackEvent } from '@floori-web/models';
import { BaseTrackingProvider } from './base-tracking.provider';

export const FLOORI_GTM_PROVIDER = new InjectionToken('FLOORI_GTM_PROVIDER');

export interface FlooriGtmProvider extends BaseTrackingProvider {
    dataLayer: unknown[];

    addGtmToDom(gtmKey: string): Promise<boolean>;

    pushOnDataLayer(obj: GtmTrackEvent): void;
}
