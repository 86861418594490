import { BaseEffects } from '@floori-web/utils/abstract';
import { createEffect, ofType } from '@ngrx/effects';
import { errorActions } from '@floori-web/store';
import { takeUntil, tap } from 'rxjs';
import { inject, Injectable, OnDestroy } from '@angular/core';
import {
    DialogProvider,
    ErrorDialogData,
    FLOORI_DIALOG_SERVICE,
    FlooriTrackingEventsList,
} from '@floori-web/models';
import { FlooriDialogRef } from '@floori-web/ui/dialog';
import { FLOORI_TRACKING_PROVIDER, flooriHelperClasses } from '@floori-web/constants';
import { ErrorDialogComponent } from '@floori/ui/error-dialog';
import { FlooriTrackingProvider } from '@floori-web/tracking';

@Injectable()
export class ErrorEffects extends BaseEffects implements OnDestroy {
    private readonly dialog = inject<DialogProvider>(FLOORI_DIALOG_SERVICE);
    private readonly trackingProvider = inject<FlooriTrackingProvider>(FLOORI_TRACKING_PROVIDER);
    private dialogRef?: FlooriDialogRef<ErrorDialogComponent, ErrorDialogData>;

    private readonly setErrorWindow$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(errorActions.setError),
                tap(({ show, message }) => {
                    if (this.dialogRef && show) {
                        return;
                    }

                    if (!show) {
                        this.dialogRef?.close();
                        return;
                    }

                    this.showDialog(message);
                }),
            ),
        { dispatch: false },
    );

    private showDialog(message?: string): void {
        this.trackingProvider.track(FlooriTrackingEventsList.noConnection);
        this.dialogRef = this.dialog.open<ErrorDialogComponent, ErrorDialogData>(
            ErrorDialogComponent,
            {
                backdropClass: flooriHelperClasses['darkOverlay'],
                panelClass: flooriHelperClasses['centerOverlay'],
                data: {
                    message: message || 'connectionError',
                },
            },
        ) as FlooriDialogRef<ErrorDialogComponent, ErrorDialogData>;
        this.dialogRef
            .afterClosed()
            .pipe(
                takeUntil(this.destroyed$),
                tap(() => (this.dialogRef = undefined)),
            )
            .subscribe();
    }
}
