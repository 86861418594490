import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FLOORI_ICON_FAMILY, flooriSpritePath } from '@floori-web/constants';
import { FlooriIcons } from '@floori/models';
import { Subject, takeUntil, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'floori-icon',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './floori-icon.component.html',
    styleUrls: ['./floori-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlooriIconComponent implements OnInit, OnDestroy {
    svgContent: SafeHtml = '';
    private _iconName?: string;
    private readonly destroy$ = new Subject<void>();

    @Input() set name(val: string) {
        if (val !== this._iconName) {
            this._iconName = val;
            this.cdr.markForCheck();
        }
    }

    get name(): string {
        return this._iconName ? `${flooriSpritePath}/${this.iconFamily}.svg#${this._iconName}` : '';
    }

    get url(): string {
        return this._iconName || '';
    }

    get hasValidUrl(): boolean {
        try {
            return !!new URL(this.url);
        } catch (error) {
            return false;
        }
    }

    get hasIcon(): boolean {
        return !!this._iconName;
    }

    constructor(
        private readonly cdr: ChangeDetectorRef,
        @Inject(FLOORI_ICON_FAMILY) private readonly iconFamily: FlooriIcons,
        private readonly http: HttpClient,
        private readonly sanitizer: DomSanitizer,
    ) {
        this.cdr.detach();
    }

    ngOnInit(): void {
        this.cdr.reattach();
        this.cdr.markForCheck();
        this.loadSvgContent();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private loadSvgContent(): void {
        if (!this.hasValidUrl) {
            return;
        }

        this.http
            .get(this.url, { responseType: 'text' })
            .pipe(
                takeUntil(this.destroy$),
                tap(content => {
                    this.svgContent = this.sanitizer.bypassSecurityTrustHtml(content);
                    this.cdr.markForCheck();
                }),
            )
            .subscribe();
    }
}
