import { AfterViewInit, Directive, inject, OnInit, Type, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// @ts-ignore
@Directive()
export abstract class LayoutBase implements OnInit, AfterViewInit {
    protected route = inject(ActivatedRoute);
    protected containerRef = inject(ViewContainerRef);

    protected get layout(): Type<unknown> {
        const { layout } = this.route.snapshot.data;
        return layout;
    }

    ngOnInit(): void {
        if (this.layout) {
            this.containerRef.createComponent(this.layout);
        }
    }

    ngAfterViewInit(): void {}
}
