import { Inject, Injectable, Type } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { filter, map, Observable, of, switchMap } from 'rxjs';
import { FLOORI_LAYOUTS } from '@floori-web/constants';
import { ConfigProvider, FLOORI_CONFIG_PROVIDER, LayoutsLoader } from '@floori-web/models';
import { FlooriPages } from '@floori/models';

@Injectable()
export class LayoutResolver<T = unknown> implements Resolve<Type<T> | null> {
    constructor(
        @Inject(FLOORI_CONFIG_PROVIDER) private readonly configProvider: ConfigProvider,
        @Inject(FLOORI_LAYOUTS) private readonly layoutService: LayoutsLoader,
    ) {}

    resolve(route: ActivatedRouteSnapshot): Observable<Type<T> | null> {
        const page = (route.data as { page: FlooriPages }).page;
        if (!page) {
            return of(null);
        }

        return this.configProvider.pagesLayouts$.pipe(
            filter(layouts => !!layouts?.[page]),
            map(layouts => layouts?.[page]),
            switchMap(layoutType =>
                layoutType ? this.layoutService.loadLayout<T>(layoutType) : of(null),
            ),
        );
    }
}
