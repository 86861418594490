import { createReducer, on } from '@ngrx/store';
import { visitorActions, VisitorState } from '@floori-web/store';

const writeVisitorId = on(
    visitorActions.writeVisitorId,
    (state: VisitorState, { visitorId }): VisitorState => ({
        visitorId,
    }),
);

export const visitorReducer = createReducer({}, writeVisitorId);
