// Cannot change names of events to camelCase
/* eslint-disable @typescript-eslint/naming-convention */
import { createActionGroup, props } from '@ngrx/store';
import { RouterNavigationEvent } from '@floori-web/models';

export const routerActions = createActionGroup({
    source: 'Router',
    events: {
        'Route Navigation Start': props<RouterNavigationEvent>(),
        'Route Navigation End': props<RouterNavigationEvent>(),
        'Route Navigation Error': props<RouterNavigationEvent>(),
    },
});
