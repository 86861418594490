import { CustomButtonTypes, FieldTranslation } from '@floori/models';

export enum RoomActionButtonType {
    catalog = 'catalog',
    downloadScreen = 'downloadScreen',
    favourite = 'favourite',
    settings = 'settings',
    share = 'share',
    help = 'help',
    loading = 'loading',
}

export const roomActionButtonsLabels: Record<RoomActionButtonType, FieldTranslation> = {
    [RoomActionButtonType.catalog]: { default: 'room.products.header' },
    [RoomActionButtonType.settings]: { default: 'room.settings.header' },
    [RoomActionButtonType.help]: { default: 'room.help.header' },
    [RoomActionButtonType.favourite]: { default: 'room.favourite.header' },
    [RoomActionButtonType.share]: { default: 'room.share.header' },
    [RoomActionButtonType.downloadScreen]: { default: 'room.download.header' },
    [RoomActionButtonType.loading]: { default: '' },
};
export const customActionButtonsLabels: Record<CustomButtonTypes, FieldTranslation> = {
    [CustomButtonTypes.quote]: { default: 'quote.standardTitle' },
    [CustomButtonTypes.calculator]: { default: 'calculator.title' },
    [CustomButtonTypes.addToCart]: { default: 'room.selectedProducts.label' },
    [CustomButtonTypes.custom]: { default: '' },
    [CustomButtonTypes.devtools]: { default: 'devtools' },
    [CustomButtonTypes.sample]: { default: 'sample.standardTitle' },
    [CustomButtonTypes.welcomeScreen]: { default: '' },
};

export const roomActionButtonsIcons: Record<RoomActionButtonType, string> = {
    [RoomActionButtonType.catalog]: 'fl_catalog_o',
    [RoomActionButtonType.settings]: 'fl_pattern_o',
    [RoomActionButtonType.help]: 'fl_question_mark_circle_o',
    [RoomActionButtonType.favourite]: 'fl_heart_o',
    [RoomActionButtonType.share]: 'fl_share_o',
    [RoomActionButtonType.downloadScreen]: 'fl_arrow_down_tray_o',
    [RoomActionButtonType.loading]: '',
};

export const customActionButtonsIcons: Record<CustomButtonTypes, string> = {
    [CustomButtonTypes.custom]: '',
    [CustomButtonTypes.quote]: 'fl_quote_o',
    [CustomButtonTypes.calculator]: 'fl_calculator_o',
    [CustomButtonTypes.addToCart]: 'fl_hotpoint_o',
    [CustomButtonTypes.devtools]: 'fl_devtools_o',
    [CustomButtonTypes.sample]: '',
    [CustomButtonTypes.welcomeScreen]: '',
};
