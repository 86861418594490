import { Event, EventType, Navigation, ParamMap } from '@angular/router';

export class RouterNavigationEvent {
    constructor(
        public readonly event: Event,
        public readonly eventType: EventType,
        public readonly url: string,
        public readonly queryParams: ParamMap,
        public readonly navigation: Partial<Navigation>,
    ) {}
}
