type Endpoints = 'company' | 'room' | 'rooms';
export const flooriEndpoints: Record<Endpoints, Record<string, string>> = {
    company: {
        vendorId: 'v3/studio/visualization/vendor-id/:subdomain',
        config: 'v3/studio/visualization/config',
    },
    rooms: {
        baseRooms: 'v3/studio/room',
        getRoomTypes: 'get-room-types',
        createRoom: 'v3/studio/room/:visitorId',
    },
    room: {
        arLinks: 'v3/studio/visualization/ar-links/:variantId',
        breadcrumb: 'v3/studio/explore/product/breadcrumbs/:productId',
        ctaButtons: 'v3/studio/visualization/cta-buttons',
        estimation: 'v3/studio/visualization/estimation',
        filters: 'v3/studio/visualization/product-filters',
        session: 'v3/studio/visualization/session',
        filterProducts: 'v3/studio/explore/filters',
        product: 'v3/studio/explore',
        quote: 'v3/studio/visualization/quote',
    },
} as const;
