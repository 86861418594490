import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import {
    ActionButton,
    ArLinksDto,
    BreadcrumbItemDto,
    EstimationDto,
    PagedResponse,
    ProductExtended,
    ProductFetchParams,
    ProductFilterDefinitionDto,
    ProductFilterResponseDto,
    ProductsFilterData,
    SendQuoteDto,
} from '@floori/models';
import { RoomActionBtn } from '../room';

export const FLOORI_ROOM_API = new InjectionToken('FLOORI_ROOM_API');

export interface RoomApiProvider {
    addSession(roomId: string): Observable<void>;

    fetchCtaButtons<T extends ActionButton>(): Observable<RoomActionBtn<T>[]>;

    fetchBreadcrumb(productId?: string): Observable<BreadcrumbItemDto[]>;

    fetchProduct(options?: ProductFetchParams): Observable<ProductExtended | undefined>;

    fetchEstimation(roomId: string, visitorId: string): Observable<EstimationDto>;

    fetchFilterDefinitions(isLiteRequest?: boolean): Observable<ProductFilterDefinitionDto[]>;

    filterProducts(params: ProductsFilterData): Observable<PagedResponse<ProductFilterResponseDto>>;

    getArLinksUrl(formData: FormData, variantId: string): Observable<ArLinksDto>;

    sendQuote(quote: SendQuoteDto): Observable<void>;
}
