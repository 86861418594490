import { inject, Provider } from '@angular/core';
import { FLOORI_ENV, FLOORI_WINDOW } from '@floori-web/constants';
import { FlooriEnv, FlooriWindow, GtmTrackEvent } from '@floori-web/models';
import { DOCUMENT } from '@angular/common';
import { HashMap } from '@floori/models';
import { FLOORI_GTM_PROVIDER, FlooriGtmProvider } from '../providers';

export class GtmService implements FlooriGtmProvider {
    private readonly windowRef = inject<FlooriWindow>(FLOORI_WINDOW);
    private readonly document = inject<Document>(DOCUMENT);
    private readonly env = inject<FlooriEnv>(FLOORI_ENV);
    private readonly gtmTagIdBase = 'GTMScript';
    private readonly gtmContainerMap: Map<string, boolean> = new Map<string, boolean>();
    private gtmCount = 1;

    get dataLayer(): unknown[] {
        this.windowRef.dataLayer = this.windowRef.dataLayer || [];
        return this.windowRef.dataLayer;
    }

    addGtmToDom(gtmKey: string): Promise<boolean> {
        if (!this.document) {
            return Promise.resolve(false);
        }

        if (this.gtmContainerMap.get(gtmKey)) {
            return Promise.resolve(true);
        }

        return new Promise((resolve, reject) => {
            const doc = this.document;
            this.pushOnDataLayer({
                // naming convention for GTM
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'gtm.start': new Date().getTime(),
                // Disabled quote-props because once deleted prettier puts them back
                // eslint-disable-next-line quote-props
                'event': 'gtm.js',
            });
            const gtmScript = doc.createElement('script');
            gtmScript.id =
                this.gtmCount === 1 ? this.gtmTagIdBase : `${this.gtmTagIdBase}${this.gtmCount}`;
            this.gtmCount++;
            gtmScript.async = true;
            gtmScript.defer = true;
            gtmScript.src = this.applyGtmKey(gtmKey);
            gtmScript.addEventListener('load', () => {
                this.gtmContainerMap.set(gtmKey, true);
                return resolve(true);
            });
            gtmScript.addEventListener('error', () => reject(false));
            try {
                doc.head.insertBefore(gtmScript, doc.head.firstChild);
            } catch (e) {
                resolve(false);
            }
        });
    }

    pushOnDataLayer(obj: GtmTrackEvent): void {
        if (!obj?.event) {
            return;
        }

        this.dataLayer?.push({ ...obj });
    }

    track(eventName: string, props: HashMap): void {
        this.pushOnDataLayer({
            event: eventName,
            ...props,
        });
    }

    private applyGtmKey(key: string): string {
        return new URL(`gtm.js?id=${key}`, this.env.gtmUrl).href;
    }
}

export const gtmProvider: Provider = {
    provide: FLOORI_GTM_PROVIDER,
    useClass: GtmService,
};
