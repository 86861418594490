import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HammerModule } from '@angular/platform-browser';
import { AppFacade } from '@floori-web/store';
import { CommonModule } from '@angular/common';
import { FlooriWebShellUiModule } from '@floori-web/shell/ui';
import { delayWhen, interval, of } from 'rxjs';
import { FLOORI_TRACKING_PROVIDER, FLOORI_WINDOW } from '@floori-web/constants';
import { FlooriTrackingProvider } from '@floori-web/tracking';
import { FlooriTrackingEventsList, FlooriWindow } from '@floori-web/models';
import { environment } from '../environments/environment';

@Component({
    standalone: true,
    imports: [CommonModule, RouterModule, HammerModule, FlooriWebShellUiModule],
    selector: 'floori-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
    private readonly appFacade = inject(AppFacade);
    private readonly tracking = inject<FlooriTrackingProvider>(FLOORI_TRACKING_PROVIDER);
    private readonly windowRef = inject<FlooriWindow>(FLOORI_WINDOW);
    readonly splash$ = this.appFacade.splash$.pipe(
        delayWhen(splash => (!splash?.visible ? interval(250) : of(undefined))),
    );
    readonly version = environment.version;
    readonly showVersion = environment.showDebugData ? '' : 'hidden';
    readonly url = this.windowRef.location.href;

    ngOnInit(): void {
        this.tracking.track(FlooriTrackingEventsList.pageView);
    }
}
