export enum FlooriPages {
    ar = 'ar',
    calculator = 'calculator',
    room = 'room',
    rooms = 'rooms',
    notFound = 'not-found',
}

export interface LayoutsConfig {
    pagesLayout: Record<FlooriPages, Layouts | null>;
}

export enum Layouts {
    baseAr = 'baseAr',
    baseRooms = 'baseRooms',
    baseRoom = 'baseRoom',
    notFound = 'baseNotFound',
}
