import { Pipe, PipeTransform } from '@angular/core';
import { DimensionUnit, ProductTileSize } from '@floori/models';
import { unitConstants } from '@floori-web/constants';
import { UnitsConverter } from './unit-converter';

@Pipe({
    name: 'sizeMapping',
    standalone: true,
})
export class SizeMappingPipe implements PipeTransform {
    private readonly fractionDigits = 2;
    private readonly feetSymbol = `'`;
    private readonly inchSymbol = '"';

    transform(value: ProductTileSize): string {
        if (!value) {
            return '';
        }

        return this.mapSize(value)?.trim();
    }

    private mapSize(options: ProductTileSize): string {
        const unit = options?.displayUnit;
        const converter = new UnitsConverter();

        if (unit === DimensionUnit.ft) {
            // Make conversion to prepare width and height for further calculations
            const width = converter.convertFromMMOrINCHToGivenUnit(
                options.originalWidth,
                DimensionUnit.inch,
            );
            const height = converter.convertFromMMOrINCHToGivenUnit(
                options.originalHeight,
                DimensionUnit.inch,
            );
            const widhtLabel = this.createFeetLabelWithDecimalInches(width);
            const heightLabel = this.createFeetLabelWithDecimalInches(height);
            return `${widhtLabel} x ${heightLabel}`;
        }

        const width = converter.convertFromMMOrINCHToGivenUnit(options.originalWidth, unit);
        const height = converter.convertFromMMOrINCHToGivenUnit(options.originalHeight, unit);

        if (unit === DimensionUnit.inch) {
            return `${width}${this.inchSymbol} x ${height}${this.inchSymbol}`;
        }

        return `${width} x ${height} ${unit}`;
    }

    private createFeetLabelWithDecimalInches(inchValue: number): string {
        const ftValue = inchValue * unitConstants.inchToFeet;
        let ftLabel = '';
        let inchLabel = '';

        let inchDecimalPart = this.getDecimalPart(inchValue);
        const inchFractionDigits = inchDecimalPart > 0 ? this.fractionDigits : 0;

        if (ftValue > 1) {
            ftLabel = `${Math.floor(ftValue)}${this.feetSymbol}`;
            inchDecimalPart = this.getDecimalPart(ftValue) * unitConstants.feetToInch;

            if (inchDecimalPart > 0) {
                inchLabel = `${inchDecimalPart.toFixed(inchFractionDigits)}${this.inchSymbol}`;
            }
        } else {
            if (inchValue > 0) {
                inchLabel = `${inchValue.toFixed(inchFractionDigits)}${this.inchSymbol}`;
            }
        }
        return `${ftLabel} ${inchLabel}`;
    }

    private getDecimalPart(value: number): number {
        return Number(value.toPrecision(4)) % 1;
    }
}
