import { InjectionToken, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { FlooriDialogConfig, FlooriDialogRef } from '@floori-web/ui/dialog';
import { ComponentType, PositionStrategy } from '@angular/cdk/overlay';

export const FLOORI_DIALOG_DATA: InjectionToken<string> = new InjectionToken<string>(
    'FLOORI_DIALOG_DATA',
);

export const FLOORI_DIALOG_SERVICE: InjectionToken<string> = new InjectionToken<string>(
    'FLOORI_DIALOG_SERVICE',
);

export interface DialogProvider extends OnDestroy {
    get openDialogs(): FlooriDialogRef<unknown>[];

    get afterOpened(): Subject<FlooriDialogRef<unknown>>;

    closeAll(): void;

    getPositionStrategy<D>(config: FlooriDialogConfig<D>): PositionStrategy;

    getDialogById(id: string): FlooriDialogRef<unknown> | undefined;

    open<T, D = unknown, R = unknown>(
        componentRef: ComponentType<T>,
        config?: FlooriDialogConfig<D>,
        closeAfter?: boolean,
    ): FlooriDialogRef<T, R>;
}
