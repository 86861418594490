import { CompanyConfig, CompanyType } from '@floori/models';
import { bonaAdditions } from './bona-additions';

export const configWrapper = (
    config: CompanyConfig,
    vendorId: string,
    bonaAdditionsIds: string,
): CompanyConfig => {
    const addBonaAdditions = bonaAdditionsIds.split(',').includes(vendorId);

    if (addBonaAdditions) {
        return {
            ...config,
            ...bonaAdditions(config),
        };
    }

    // TODO: Think how to extend config with translationScope. Hardcoded for Bona
    if (config.companyType === CompanyType.epoxy) {
        return {
            ...config,
            dynamicFeatures: {},
        };
    }

    return {
        ...config,
    };
};
