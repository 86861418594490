import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input,
    ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlooriIconComponent } from '@floori-web/ui/icon';

@Component({
    selector: 'floori-error-info',
    standalone: true,
    encapsulation: ViewEncapsulation.None,
    imports: [CommonModule, FlooriIconComponent],
    templateUrl: './error-info.component.html',
    styleUrls: ['./error-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorInfoComponent {
    @Input() hideIcon = false;
    @Input() icon = 'fl_alert_o';
    @Input() header = '';
    @Input() message = '';

    @HostBinding('class.floori-error-info') hostClass = true;
}
