import { FlooriFontFamily, FlooriIcons, FlooriThemes } from '../styles';
import { HashMap } from '../utils';
import { FieldTranslation } from './field-translation';
import { Languages } from './language';
import { FlooriPages, Layouts } from './layouts';
import { ProductCategory } from './product-category';

export enum CompanyType {
    default,
    carpet,
    epoxy,
}

export interface CompanyConfigDto {
    readonly id: string;
    readonly company: string;
    readonly companyType: CompanyType;
    readonly cacheUploadedRooms: boolean;
    readonly defaultLanguage: Languages;
    readonly defaultProductId?: string;
    readonly disclaimer?: DisclaimerData;
    readonly filterProductsByRoomCategory: boolean;
    readonly filtersEnabled: boolean;
    readonly flooriIoLinkEnable?: boolean;
    readonly gtmKey?: string;
    readonly hideHeader?: boolean;
    readonly languages: Languages[];
    readonly logo?: string;
    readonly showProductPrice?: boolean;
    readonly supportedProductTypes?: ProductCategory[];
    readonly supportsAR?: boolean;
    readonly supportsGlossiness?: boolean;
    readonly supportsMovePattern?: boolean;
    readonly supportsGroutVisibility?: boolean;
    readonly supportsBaseColorsInCatalogsVisibility?: boolean;
    readonly selectedProductFields?: string[];
    readonly themeCssVars?: HashMap<string>;
    readonly themeSettings?: FlooriThemeSettings;
    readonly watermark?: string;
}

export interface CompanyConfig extends CompanyConfigDto {
    readonly epoxyView?: boolean;
    readonly dynamicFeatures?: DynamicFeatures;
    readonly statusCode?: number;
    readonly vendorUrl?: string;
    readonly header?: Header;
}

export interface DisclaimerData {
    id: string;
    title: string;
    text: string;
}

export interface FlooriThemeSettings {
    readonly theme: FlooriThemes;
    readonly fontFamily: FlooriFontFamily;
    readonly iconFamily: FlooriIcons;
}

export interface ConfigState {
    readonly company?: CompanyConfig;
    readonly gtmKey?: string;
    readonly layouts?: Record<FlooriPages, Layouts | null>;
    readonly logoUrl?: string;
    readonly logoSplash?: string;
    readonly theme?: FlooriThemeSettings;
    readonly langs?: Languages[];
    readonly currentLang?: Languages;
    readonly themeCssVars?: HashMap<string>;
    readonly vendorUrl?: string;
    readonly epoxyView?: boolean;
}

export interface DynamicFeatures {
    readonly roomsMessage?: string;
    readonly roomMessage?: string;
    readonly roomProductMessage?: string;
    readonly translationScope?: string;
    readonly hideProductDetails?: boolean;
    readonly roomsCtaButton?: RoomsCtaButton;
}

export interface RoomsCtaButton {
    visible: boolean;
    url: string;
    label: FieldTranslation;
    icon?: string;
}

export interface Header {
    id: string;
    customHeaderEnabled: boolean;
    titleBlack?: string;
    titleColored?: string;
    subTitle?: string;
    customImagesEnabled: boolean;
    imageDesktopUrl?: string;
    imageMobileUrl?: string;
}
