export enum FloorPattern {
    random = 'random',
    basket = 'basket',
    brick = 'brick',
    chevron = 'chevron',
    herringbone = 'herringbone',
    mix = 'mix',
    grid = 'grid',
    chessboard = 'chessboard',
    brickOneThird = 'brickOneThird',
}

export enum FloorPatterns {
    random,
    herringbone,
    brick,
    basket,
    chevron,
    mix,
    grid,
    chessboard,
    brickOneThird,
}

export enum EpoxyPatterns {
    light,
    medium,
    heavy,
}

export const epoxyDensityLabelMapper: Record<EpoxyPatterns, string> = {
    [EpoxyPatterns.light]: 'epoxy.density.light',
    [EpoxyPatterns.medium]: 'epoxy.density.medium',
    [EpoxyPatterns.heavy]: 'epoxy.density.heavy',
};

export const floorPatternMapper: Record<FloorPattern, FloorPatterns> = {
    [FloorPattern.random]: FloorPatterns.random,
    [FloorPattern.herringbone]: FloorPatterns.herringbone,
    [FloorPattern.brick]: FloorPatterns.brick,
    [FloorPattern.basket]: FloorPatterns.basket,
    [FloorPattern.chevron]: FloorPatterns.chevron,
    [FloorPattern.mix]: FloorPatterns.mix,
    [FloorPattern.grid]: FloorPatterns.grid,
    [FloorPattern.chessboard]: FloorPatterns.chessboard,
    [FloorPattern.brickOneThird]: FloorPatterns.brickOneThird,
} as const;
