import { Injectable } from '@angular/core';
import { StorageProvider, StorageType } from '@floori-web/models';
import { Observable, of } from 'rxjs';
import { devLog } from '@floori-web/tools';

@Injectable()
export class StorageService implements StorageProvider {
    private readonly localStorage = localStorage;
    private readonly sessionStorage = sessionStorage;

    set<T = string>(
        key: string,
        value: T,
        type: StorageType = StorageType.local,
    ): Observable<boolean> {
        let storage: Storage;
        try {
            storage = this.getStorage(type);
        } catch (e) {
            return of(false);
        }

        return new Observable<boolean>(obs => {
            let saved = true;
            try {
                storage.setItem(key, JSON.stringify(value));
            } catch (e) {
                devLog('Storage error', e);
                saved = false;
            }

            obs.next(saved);
            obs.complete();
        });
    }

    get<T = string>(key: string, type: StorageType = StorageType.local): Observable<T | null> {
        try {
            const storage = this.getStorage(type);
            const value = storage.getItem(key);
            return of(this.parseToType(value || '') as T);
        } catch (e) {
            devLog(e);
            return of(null);
        }
    }

    delete(key: string, type: StorageType = StorageType.local): Observable<boolean> {
        let storage: Storage;
        try {
            storage = this.getStorage(type);
        } catch (e) {
            return of(false);
        }

        return new Observable<boolean>(obs => {
            let removed = true;
            try {
                storage.removeItem(key);
            } catch (e) {
                devLog('Storage error', e);
                removed = false;
            }

            obs.next(removed);
            obs.complete();
        });
    }

    getSync<T = string>(key: string, type: StorageType = StorageType.local): T | null {
        try {
            const storage = this.getStorage(type);
            const val = storage.getItem(key) || '';
            return this.parseToType(val);
        } catch (e) {
            devLog(e);
            return null;
        }
    }

    private getStorage(type: StorageType = StorageType.local): Storage {
        return type === StorageType.session ? this.sessionStorage : this.localStorage;
    }

    private parseToType<T = string>(val: string): T {
        if (!val) {
            return '' as T;
        }
        try {
            return JSON.parse(val);
        } catch (e) {
            return val as T;
        }
    }
}
