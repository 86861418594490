import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Translation, TranslocoLoader } from '@ngneat/transloco';
import { catchError, filter, forkJoin, map, Observable, of, switchMap, take } from 'rxjs';
import { TranslocoLoaderData } from '@ngneat/transloco/lib/transloco.loader';
import { FLOORI_ENV } from '@floori-web/constants';
import { ConfigProvider, FLOORI_CONFIG_PROVIDER, FlooriEnv } from '@floori-web/models';
import { ConfigState } from '@floori/models';
import { devLog } from '@floori-web/tools';

@Injectable()
export class TranslationsLoaderService implements TranslocoLoader {
    private readonly http = inject(HttpClient);
    private readonly envs = inject<FlooriEnv>(FLOORI_ENV);
    private readonly config = inject<ConfigProvider>(FLOORI_CONFIG_PROVIDER);
    private readonly localUrl = `./assets/translations/`;

    getTranslation(lang: string, data?: TranslocoLoaderData): Observable<Translation> {
        const url = `${this.localUrl}${lang}.json`;
        return this.config.companyConfig$.pipe(
            filter(Boolean),
            take(1),
            switchMap((config: ConfigState) => {
                const scope = config.company?.dynamicFeatures?.translationScope || '';
                const scopeUrl = `${this.localUrl}${scope}/${lang}.json`;
                return forkJoin([
                    this.http.get<Translation>(url),
                    scope ? this.http.get<Translation>(scopeUrl) : of(null),
                ]);
            }),
            catchError(() => {
                devLog('Error fetching lang');
                return of([{}, null]);
            }),
            map(([translation, scopeTranslation]) => {
                let result = { ...translation };
                if (scopeTranslation) {
                    result = { ...result, ...scopeTranslation };
                }

                return result;
            }),
        );
    }
}
