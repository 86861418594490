import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const FLOORI_DRAFT_MODE_PROVIDER = new InjectionToken('FLOORI_DRAFT_MODE_PROVIDER');

export interface DraftModeProvider {
    readonly isDraftMode$: Observable<boolean>;

    init(): void;

    toggleDraftMode(): void;
}
