// Cannot change names of events to camelCase
/* eslint-disable @typescript-eslint/naming-convention */
import { createActionGroup, props } from '@ngrx/store';

export const errorActions = createActionGroup({
    source: 'Error',
    events: {
        'Set Error': props<{ show: boolean; message?: string }>(),
    },
});
