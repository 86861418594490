import {
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    ElementRef,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlooriIconComponent } from '@floori-web/ui/icon';

enum ButtonTypes {
    flooriBtn = 'floori-button',
    flooriIconBtn = 'floori-icon-button',
}

const buttonHostAttributes: string[] = [ButtonTypes.flooriBtn, ButtonTypes.flooriIconBtn];

@Component({
    // Cannot change selector because this component is used like directive
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: `button[floori-button], button[floori-icon-button]`,
    standalone: true,
    imports: [CommonModule],
    templateUrl: './floori-button.component.html',
    styleUrls: ['./floori-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class FlooriButtonComponent implements OnInit {
    @ContentChild(FlooriIconComponent) icon?: FlooriIconComponent;

    get isIconBtn(): boolean {
        return this.elRef.nativeElement.hasAttribute(ButtonTypes.flooriIconBtn);
    }

    constructor(private readonly elRef: ElementRef) {}

    ngOnInit(): void {
        this.setupAttributesClasses();
    }

    private setupAttributesClasses(): void {
        buttonHostAttributes.forEach(bha => {
            if (this.elRef.nativeElement.hasAttribute(bha)) {
                this.elRef.nativeElement.classList.add(bha);
            }
        });
    }
}
