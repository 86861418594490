import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { CqrsEvent } from './cqrs.event';

export const EVENTS_PROVIDER = new InjectionToken('EVENTS_PROVIDER');

export interface EventsProvider {
    send<T extends CqrsEvent>(event: T): void;
    listen<T extends CqrsEvent>(type: new (...args: any[]) => T): Observable<T>;
}
