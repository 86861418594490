import { Pipe, PipeTransform } from '@angular/core';
import { ProductRGBAColor } from '@floori/models';
import { ColorsConverter } from './colors-converter';

@Pipe({
    name: 'toHex',
    standalone: true,
})
export class ToHEXPipe implements PipeTransform {
    transform(rgba?: ProductRGBAColor): string {
        if (!rgba) {
            return '';
        }
        return ColorsConverter.convertRGBAToHex(rgba);
    }
}

@Pipe({
    name: 'toRGBA',
    standalone: true,
})
export class ToRGBAPipe implements PipeTransform {
    transform(hex?: string): ProductRGBAColor {
        if (!hex) {
            return { r: 0, g: 0, b: 0, a: 1 };
        }
        return ColorsConverter.convertHexToRGBA(hex);
    }
}
