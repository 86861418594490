import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from '@angular/cdk/dialog';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { FLOORI_DIALOG_SERVICE } from '@floori-web/models';
import { FlooriDialogContainerComponent } from './components/floori-dialog-container/floori-dialog-container.component';
import { FlooriDialogService } from './services/floori-dialog.service';
import {
    FlooriDialogActionsDirective,
    FlooriDialogCloseDirective,
    FlooriDialogContentDirective,
    FlooriDialogTitleDirective,
} from './directives/floori-dialog-directives';

const EXPORTED = [
    FlooriDialogContainerComponent,
    FlooriDialogActionsDirective,
    FlooriDialogCloseDirective,
    FlooriDialogContentDirective,
    FlooriDialogTitleDirective,
];

@NgModule({
    imports: [CommonModule, DialogModule, OverlayModule, PortalModule],
    declarations: [...EXPORTED],
    exports: [...EXPORTED],
    providers: [
        {
            provide: FLOORI_DIALOG_SERVICE,
            useClass: FlooriDialogService,
        },
    ],
})
export class FlooriDialogModule {}
