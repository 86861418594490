import { InjectionToken } from '@angular/core';
import { ArLinksDto, OS, ProductExtended } from '@floori/models';

export const FLOORI_AR_PROVIDER = new InjectionToken('FLOORI_AR_PROVIDER');

export interface ArProvider {
    showAr(link: string): void;

    arAvailable(product?: ProductExtended | null): boolean;

    resolveArLink(links: ArLinksDto): string;

    extractArUrlFromProduct(product: ProductExtended): string;

    prepareFormDataFromGltf(os: OS, gltf: ArrayBuffer): FormData;
}
