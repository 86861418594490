import { AbstractControl, ValidatorFn } from '@angular/forms';
import { flooriConstants } from '@floori-web/constants';

export const flooriPhoneValidator: ValidatorFn = (control: AbstractControl) => {
    const pattern = flooriConstants.phoneFormat;
    if (!control?.value) {
        return null;
    }

    const result = pattern.test(control.value);
    if (result) {
        return null;
    }

    control.setErrors({ invalidPhone: true });
    return control.errors;
};
