import { ChangeDetectorRef, Inject, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { FLOORI_TRANSLATOR } from '@floori-web/constants';
import { TranslationsProvider } from '@floori-web/models';
import { FieldTranslation, HashMap, Languages } from '@floori/models';
import { filter, Subscription } from 'rxjs';

@Pipe({
    name: 'flTranslate',
    pure: false,
})
export class TranslatePipe implements PipeTransform, OnDestroy {
    private translation = '';
    private subscription = Subscription.EMPTY;
    private currentLang?: Languages;
    private currentKey?: string | FieldTranslation;

    constructor(
        private readonly cdr: ChangeDetectorRef,
        @Inject(FLOORI_TRANSLATOR) private readonly translationsProvider: TranslationsProvider,
    ) {}

    transform(key?: string | FieldTranslation, params?: HashMap, lang?: string): string {
        if (!key) {
            return '';
        }
        if (this.currentKey && this.currentKey !== key) {
            this.currentKey = key;
            this.translation = this.translationsProvider.translate(
                key,
                params,
                lang ?? this.currentLang,
            );
            return this.translation;
        }
        this.subscription = this.translationsProvider.langChange$
            .pipe(filter(cLang => cLang !== this.currentLang))
            .subscribe(cLang => {
                this.currentLang = cLang as Languages;
                this.currentKey = key;
                this.translation = this.translationsProvider.translate(
                    key,
                    params,
                    lang ?? this.currentLang,
                );
                this.cdr.markForCheck();
            });
        return this.translation;
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
