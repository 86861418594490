import { RoomDto } from '@floori/models';

export class BaseRoomCategory {
    constructor(public readonly key: string = '', public items: RoomDto) {}

    static loadMock(): BaseRoomCategory {
        return {
            key: '',
            items: {},
        };
    }
}
