import { Navigation, RouterStateSnapshot } from '@angular/router';
import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { RouterNavigationEvent } from './router-navigation.event';

export const FLOORI_NAVIGATION_EVENTS = new InjectionToken('FLOORI_NAVIGATION_EVENTS');
export interface NavigationEventsProvider {
    navigation: Navigation | null;

    snapshot: RouterStateSnapshot;

    lastRoute$: Observable<RouterNavigationEvent>;

    init(): void;
}
