import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

// @ts-ignore
@Directive()
export abstract class NgDestroyed implements OnDestroy {
    protected destroyed = new Subject<boolean>();

    ngOnDestroy(): void {
        this.destroyed.next(true);
        this.destroyed.complete();
    }
}
