import { flooriEndpoints, flooriRoutes } from '@floori-web/constants';
import { CompanyConfig, CompanyConfigDto } from '@floori/models';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class SetupService {
    private readonly pathnameKey = flooriRoutes['preview'];
    private _clientKey = '';

    get clientKey(): string {
        return this._clientKey;
    }

    bootstrap(): Promise<CompanyConfig> {
        const { hostname, pathname } = window.location;
        const pathSegments = pathname?.split('/').filter(p => p);
        const fromPreview =
            pathSegments?.[0]?.toLowerCase()?.trim() === this.pathnameKey &&
            pathSegments?.length > 1;
        if (fromPreview) {
            this._clientKey = pathSegments?.[1];
            return this.fetchCompanyInfo(this._clientKey);
        }

        const companyName = hostname?.split('.').filter(p => p)?.[0];
        return this.fetchVendorId(companyName).then(vendorId => {
            this._clientKey = vendorId;
            return this.fetchCompanyInfo(this._clientKey);
        });
    }

    private async fetchCompanyInfo(clientKey: string): Promise<CompanyConfigDto> {
        const url = `${environment.apiUrl}/${flooriEndpoints.company['config']}`;
        return await fetch(url, {
            method: 'GET',
            headers: {
                authorization: `Bearer ${clientKey}`,
            },
        }).then(res => res.json());
    }

    private async fetchVendorId(companyName: string): Promise<string> {
        const url = `${environment.apiUrl}/${flooriEndpoints.company['vendorId']}`.replace(
            ':subdomain',
            companyName,
        );
        return await fetch(url, {
            method: 'GET',
        })
            .then(res => res.json())
            .then(body => body?.vendorId);
    }
}
