import { filter, merge, Observable, Subject } from 'rxjs';
import { DialogRef } from '@angular/cdk/dialog';
import { hasModifierKey } from '@angular/cdk/keycodes';
import { GlobalPositionStrategy } from '@angular/cdk/overlay';
import { FlooriDialogContainerComponent } from '../components/floori-dialog-container/floori-dialog-container.component';
import { FlooriDialogConfig, FlooriDialogPosition } from './floori-dialog-config';

export const enum FlooriDialogState {
    open = 1,
    closing,
    closed,
}

export class FlooriDialogRef<T = unknown, R = unknown> {
    componentInstance?: T;
    disableClose: boolean | undefined;
    id: string;

    private readonly _afterOpened = new Subject<void>();
    private readonly _beforeClosed = new Subject<R | undefined>();
    private _state?: FlooriDialogState = FlooriDialogState.open;
    private result: R | undefined;

    get state(): FlooriDialogState | undefined {
        return this._state;
    }

    constructor(
        private readonly dialogRef: DialogRef<R, T>,
        private readonly config: FlooriDialogConfig,
        public containerInstance: FlooriDialogContainerComponent,
    ) {
        this.disableClose = config.disableClose;
        this.id = dialogRef.id;
        dialogRef.overlayRef.detachments().subscribe(() => {
            this._beforeClosed.next(this.result);
            this._beforeClosed.complete();
            this.finishDialogClose();
        });

        merge(
            this.backdropClick(),
            this.keydownEvents().pipe(
                filter(
                    event =>
                        event.key?.toLowerCase() === 'enter' &&
                        !this.disableClose &&
                        !hasModifierKey(event),
                ),
            ),
        ).subscribe(event => {
            if (!this.disableClose) {
                event.preventDefault();
                this.dialogRef.close(this.result);
            }
        });
    }

    addPanelClass(classes: string | string[]): this {
        this.dialogRef.addPanelClass(classes);
        return this;
    }

    removePanelClass(classes: string | string[]): this {
        this.dialogRef.removePanelClass(classes);
        return this;
    }

    afterClosed(): Observable<R | undefined> {
        return this.dialogRef.closed;
    }

    afterOpened(): Observable<void> {
        return this._afterOpened;
    }

    beforeClosed(): Observable<R | undefined> {
        return this._beforeClosed;
    }

    backdropClick(): Observable<MouseEvent> {
        return this.dialogRef.backdropClick;
    }

    close(dialogResult?: R): void {
        this.result = dialogResult;

        if (this._state === FlooriDialogState.open) {
            this._state = FlooriDialogState.closing;
            this.finishDialogClose();
        }

        // this.containerInstance._startExitAnimation();
    }

    keydownEvents(): Observable<KeyboardEvent> {
        return this.dialogRef.keydownEvents;
    }

    private finishDialogClose(): void {
        this._state = FlooriDialogState.closed;
        this.dialogRef.close(this.result);
        this.componentInstance = undefined;
    }

    updatePosition(position: FlooriDialogPosition): this {
        const strategy = this.dialogRef.config.positionStrategy as GlobalPositionStrategy;

        if (position && (position.left || position.right)) {
            //Nested if to avoid eslint no-unused-expressions
            if (position.left) {
                strategy?.left(position.left);
            } else {
                strategy?.right(position.right);
            }
        } else {
            strategy?.centerHorizontally();
        }

        if (position && (position.top || position.bottom)) {
            //Nested if to avoid eslint no-unused-expressions
            if (position.top) {
                strategy?.top(position.top);
            } else {
                strategy?.bottom(position.bottom);
            }
        } else {
            strategy?.centerVertically();
        }

        this.dialogRef.updatePosition();

        return this;
    }
}
