import { InjectionToken } from '@angular/core';
import { FlooriWindow } from '@floori-web/models';

export const FLOORI_WINDOW = new InjectionToken<FlooriWindow>('floori global window object', {
    factory: (): FlooriWindow => self as unknown as FlooriWindow,
});

export const FLOORI_ICON_FAMILY = new InjectionToken('FLOOR_ICON_FAMILY');

export const FLOORI_TRANSLATOR = new InjectionToken('FLOORI_TRANSLATOR');

export const FLOORI_ENV = new InjectionToken('FLOORI_ENV');

export const FLOORI_LAYOUTS = new InjectionToken('FLOORI_LAYOUTS');

export const FLOORI_CONTEXT_ID = new InjectionToken('FLOORI_CONTEXT_ID');

export const FLOORI_TRACKING_PROVIDER = new InjectionToken('FLOORI_INTERACTION_PROVIDER');
