import { CqrsEvent } from '@floori-web/cqrs';
import { VisualisationActionsType } from '../room/visualisation-actions-type';

export class ProductSettingsEvent extends CqrsEvent {
    readonly type = VisualisationActionsType.settings;

    constructor(public readonly close?: boolean) {
        super();
    }
}

export class ProductsCatalogEvent extends CqrsEvent {
    readonly type = VisualisationActionsType.catalog;
}
