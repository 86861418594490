// Cannot change names of events to camelCase
/* eslint-disable @typescript-eslint/naming-convention */
import { createActionGroup, props } from '@ngrx/store';

export const visitorActions = createActionGroup({
    source: 'Visitor',
    events: {
        'Set Visitor Id': props<{ visitorId: string }>(),
        'Write Visitor Id': props<{ visitorId: string }>(),
    },
});
