import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
    name: 'safeHtml',
    standalone: true,
    pure: true,
})
export class SafeHtmlPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}

    transform(html: string): SafeResourceUrl | null {
        return html ? this.sanitizer.bypassSecurityTrustHtml(html) : null;
    }
}
