import { DialogConfig } from '@angular/cdk/dialog';
import { ElementRef } from '@angular/core';
import { ConnectedPosition } from '@angular/cdk/overlay';

export class FlooriDialogConfig<D = unknown> extends DialogConfig<D> {
    position?: FlooriDialogPosition;
    connectedPosition?: ConnectedPosition;
    positionRef?: ElementRef;
    containerClass?: string | string[];
}

export enum FlooriDialogPredefinedPosition {
    center = 'center',
}

export interface FlooriDialogPosition {
    /** Override for the dialog's top position. */
    top?: string;

    /** Override for the dialog's bottom position. */
    bottom?: string;

    /** Override for the dialog's left position. */
    left?: string;

    /** Override for the dialog's right position. */
    right?: string;
}
