import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EVENTS_PROVIDER } from './models';
import { EventsService } from './events/events.service';

@NgModule({
    imports: [CommonModule],
})
export class CqrsModule {
    static forRoot(): ModuleWithProviders<any> {
        return {
            ngModule: CqrsModule,
            providers: [
                {
                    provide: EVENTS_PROVIDER,
                    useClass: EventsService,
                },
            ],
        };
    }
}
