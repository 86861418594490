import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CompanyConfig, ConfigState, Languages } from '@floori/models';
import { ConfigProvider } from '@floori-web/models';
import { distinctUntilChanged, map } from 'rxjs';
import { changeLang, initConfig } from './config.actions';
import {
    selectCompanyConfig,
    selectCompanyLogo,
    selectConfigFeature,
    selectPagesLayouts,
} from './config.selectors';

@Injectable()
export class ConfigFacade implements ConfigProvider {
    readonly companyConfig$ = this.store.select(selectConfigFeature);
    readonly companyConfigRaw$ = this.store.select(selectCompanyConfig);
    readonly dynamicFeatures$ = this.companyConfig$.pipe(
        map(config => config?.company?.dynamicFeatures),
    );
    readonly pagesLayouts$ = this.store.select(selectPagesLayouts);
    readonly companyLogo$ = this.store
        .select(selectCompanyLogo)
        .pipe(distinctUntilChanged((p, c) => p !== c));

    constructor(private readonly store: Store<ConfigState>) {}

    initConfig(companyConfig: CompanyConfig): void {
        this.store.dispatch(initConfig({ companyConfig }));
    }

    changeLang(code: Languages): void {
        if (!code) {
            return;
        }
        this.store.dispatch(changeLang({ lang: code }));
    }
}
