export enum FlooriTrackingEventsList {
    addToCart = 'cart',
    addToFav = 'add_to_favorites',
    roomSelected = 'room_select',
    pageView = 'page_view',
    photoUploaded = 'upload_photo',
    itemSelect = 'item_select',
    quoteSent = 'quote_sent',
    buttonClick = 'button_click',
    productNotFound = 'product_not_found',
    uploadPhotoOrientation = 'upload_photo_orientation',
    noConnection = 'no_connection',
    visualizationDownload = 'visualization_download',
}
