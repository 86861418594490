import { createReducer, on } from '@ngrx/store';
import { FlooriRouterState, routerActions } from '@floori-web/store';

const routerNavigationEnd = on(
    routerActions.routeNavigationEnd,
    (state: FlooriRouterState, payload): FlooriRouterState => ({
        ...state,
        navigation: { ...payload.navigation },
        event: payload.event,
        eventType: payload.eventType,
        queryParams: payload.queryParams,
        url: payload.url,
    }),
);

export const routerReducer = createReducer({}, routerNavigationEnd);
