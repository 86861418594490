import { NgModule } from '@angular/core';
import { DeviceService, HtmlRendererService } from './services';

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [DeviceService, HtmlRendererService],
})
export class ToolsModule {}
