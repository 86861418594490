import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ConfigState } from '@floori/models';
import { CONFIG_FEATURE_KEY } from './config.reducer';

export const selectConfigFeature = createFeatureSelector<ConfigState>(CONFIG_FEATURE_KEY);

export const selectCompanyConfig = createSelector(
    selectConfigFeature,
    (state: ConfigState) => state.company,
);

export const selectPagesLayouts = createSelector(
    selectConfigFeature,
    (state: ConfigState) => state.layouts,
);

export const selectCompanyLogo = createSelector(
    selectConfigFeature,
    (state: ConfigState) => state.company?.logo || '',
);
