import { InjectionToken } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export const PRODUCTS_VIEW_DYNAMIC_TOKEN = new InjectionToken('PRODUCTS_VIEW_DYNAMIC_TOKEN');

export interface DynamicHeaderData {
    headerDynamicMessage?: string;
    headerLabel?: string;
    headerIcon?: string;
}

export interface ProductsViewDynamicProvider {
    actionBtnCallback?: () => void;
    actionBtnCallback$?: Observable<void>;
    headerData?: Subject<DynamicHeaderData>;
}
