import { ModuleWithProviders, NgModule } from '@angular/core';
import {
    TRANSLOCO_CONFIG,
    TRANSLOCO_LOADER,
    TranslocoConfig,
    translocoConfig,
    TranslocoModule,
} from '@ngneat/transloco';
import { FLOORI_ENV, FLOORI_TRANSLATOR } from '@floori-web/constants';
import {
    FLOORI_STORAGE_PROVIDER,
    FlooriEnv,
    LocalStorageKeys,
    StorageProvider,
} from '@floori-web/models';
import { HttpClientModule } from '@angular/common/http';
import { Languages } from '@floori/models';
import { FlooriTranslationService } from './services/floori-translation.service';
import { TranslationsLoaderService } from './services/translations-loader.service';
import { TranslatePipe } from './pipes/translate.pipe';

const exported = [TranslatePipe];

@NgModule({
    declarations: [...exported],
    imports: [HttpClientModule],
    exports: [TranslocoModule, ...exported],
    providers: [TranslatePipe],
})
export class TranslationsModule {
    static forRoot(): ModuleWithProviders<TranslationsModule> {
        return {
            ngModule: TranslationsModule,
            providers: [
                TranslatePipe,
                {
                    provide: TRANSLOCO_CONFIG,
                    useFactory: (env: FlooriEnv, storage: StorageProvider): TranslocoConfig => {
                        const userLang = storage.getSync(LocalStorageKeys.visitorLang);
                        return translocoConfig({
                            prodMode: env.production,
                            reRenderOnLangChange: true,
                            defaultLang: userLang || Languages.en,
                        });
                    },
                    deps: [FLOORI_ENV, FLOORI_STORAGE_PROVIDER],
                },
                {
                    provide: FLOORI_TRANSLATOR,
                    useClass: FlooriTranslationService,
                },
                {
                    provide: TRANSLOCO_LOADER,
                    useClass: TranslationsLoaderService,
                },
            ],
        };
    }
}
