import { ShoppableHotpoint } from '@floori-web/models';
import { RoomEnvironmentMapDto } from '@floori-web/visualization-engine';
import { PlaneObject, VectorBasis } from '@floori-web/visualization-models';

export enum VisualizationLightOperation {
    default = 'DEFAULT',
    overlay = 'OVERLAY',
}

export interface EstimationDto {
    hasFullScale: boolean;
    id?: string;
    name?: string;
    roomTexture: string;
    maskTexture: string;
    lightTexture: string;
    lightOperation?: VisualizationLightOperation;
    vectors?: VectorBasis;
    cotFov2?: number;
    verticalFov?: number;
    operatorAltitude?: number;
    environmentMap?: RoomEnvironmentMapDto;
    visitorRoom?: boolean;
    carpetPosition?: PlaneXYPosition;
    rugs: PlaneObject[];
    floors: PlaneObject[];
    countertops: PlaneObject[];
    walls: PlaneObject[];
    trims: PlaneObject[];
    cabinets: PlaneObject[];
    doors: PlaneObject[];
    doorFrames: PlaneObject[];
    stairs: PlaneObject[];
    ceilings: PlaneObject[];
    shoppableHotpoint: ShoppableHotpoint[];
    rugPosition?: { x: number; y: number };
}

export interface PlaneXYPosition {
    x: number;
    y: number;
}
