import { Pipe, PipeTransform } from '@angular/core';
import { AssetImageDto } from '@floori/models';

@Pipe({
    name: 'resolveImg',
    standalone: true,
    pure: true,
})
export class ResolveImgPipe implements PipeTransform {
    transform(value?: AssetImageDto): string {
        return value?.medium || value?.large || value?.small || '';
    }
}
