import { Routes } from '@angular/router';
import { flooriRoutes } from '@floori-web/constants';
import { FlooriPages } from '@floori/models';
import { LayoutResolver } from '@floori-web/utils/resolvers/src';
import { configGuard } from '../providers/config.guard';

const innerRoutes: Routes = [
    {
        path: flooriRoutes.ar,
        data: { page: FlooriPages.ar },
        resolve: {
            layout: LayoutResolver,
        },
        canActivate: [configGuard],
        loadComponent: () =>
            import('@floori-web/pages/ar/src/lib/ar.page').then(mod => mod.ArPageComponent),
    },
    {
        path: flooriRoutes.calculator,
        canActivate: [configGuard],
        loadComponent: () =>
            import('@floori-web/pages/calculator/src/lib/calculator.page').then(
                mod => mod.CalculatorPageComponent,
            ),
    },
    {
        path: flooriRoutes.rooms,
        data: { page: FlooriPages.rooms },
        canActivate: [configGuard],
        resolve: {
            layout: LayoutResolver,
        },
        loadComponent: () =>
            import('@floori-web/pages/rooms/src/lib/rooms.page').then(
                mod => mod.RoomsPageComponent,
            ),
    },
    {
        path: flooriRoutes.room,
        canActivate: [configGuard],
        data: { page: FlooriPages.room },
        resolve: {
            layout: LayoutResolver,
        },
        loadChildren: () =>
            import('@floori-web/pages/room/src/lib/room.routes').then(
                roomRoutes => roomRoutes.roomRoutes,
            ),
    },
];

export const appRoutes: Readonly<Routes> = [
    {
        path: `${flooriRoutes.preview}/:uid`,
        data: { page: FlooriPages.rooms },
        children: innerRoutes,
    },
    {
        path: '',
        data: { page: FlooriPages.rooms },
        children: innerRoutes,
    },
    {
        path: flooriRoutes.notFound,
        data: { page: FlooriPages.notFound },
        resolve: {
            layout: LayoutResolver,
        },
        loadComponent: () =>
            import('@floori-web/pages/not-found/src/lib/not-found.page').then(
                notFound => notFound.NotFoundPageComponent,
            ),
    } as const,
    { path: '**', pathMatch: 'full', redirectTo: flooriRoutes.notFound } as const,
];
