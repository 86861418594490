import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FLOORI_DRAFT_MODE_PROVIDER } from '@floori-web/models';
import { RouterModule } from '@angular/router';
import { DraftModeService } from './draft-mode/draft-mode.service';

@NgModule({
    imports: [CommonModule, RouterModule],
    providers: [
        {
            provide: FLOORI_DRAFT_MODE_PROVIDER,
            useClass: DraftModeService,
        },
    ],
})
export class DraftModeModule {}
