import { Inject, Injectable } from '@angular/core';
import { FLOORI_WINDOW, isoLanguages, isoToFlooriLangs } from '@floori-web/constants';
import {
    ConfigProvider,
    FLOORI_CONFIG_PROVIDER,
    FLOORI_STORAGE_PROVIDER,
    FlooriWindow,
    LocalStorageKeys,
    StorageProvider,
    TranslationsProvider,
} from '@floori-web/models';
import { distinctUntilChanged, map, Observable, of, switchMap, take } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';
import { FieldTranslation, HashMap, Languages } from '@floori/models';

@Injectable()
export class FlooriTranslationService implements TranslationsProvider {
    readonly langChange$ = this.translocoService.langChanges$.pipe(distinctUntilChanged());

    get currentLang(): Languages {
        const isoLang = this.currentLangIso;
        return isoToFlooriLangs[isoLang] as Languages;
    }

    get currentLangIso(): string {
        return this.translocoService.getActiveLang();
    }

    constructor(
        private readonly translocoService: TranslocoService,
        @Inject(FLOORI_WINDOW) private readonly windowRef: FlooriWindow,
        @Inject(FLOORI_CONFIG_PROVIDER) private readonly configProvider: ConfigProvider,
        @Inject(FLOORI_STORAGE_PROVIDER) private readonly storageProvider: StorageProvider,
    ) {}

    init(): Observable<void> {
        return this.configProvider.companyConfig$.pipe(
            take(1),
            switchMap(config => {
                let savedLang = this.storageProvider.getSync(LocalStorageKeys.visitorLang);
                savedLang = config.company?.languages.includes(savedLang as Languages)
                    ? savedLang
                    : '';
                const usersLang = savedLang || config.currentLang || Languages.en;
                if (config.langs?.length === 0) {
                    this.translocoService.setAvailableLangs([isoLanguages[usersLang]]);
                } else {
                    this.translocoService.setAvailableLangs(
                        config.langs?.map(lang => isoLanguages[lang]) || [],
                    );
                }
                this.translocoService.setDefaultLang(
                    isoLanguages[usersLang] || (usersLang as string),
                );
                this.translocoService.setActiveLang(
                    isoLanguages[usersLang] || (usersLang as string),
                );
                return this.translocoService.load(isoLanguages[usersLang] || (usersLang as string));
            }),
            map(() => void 0),
        );
    }

    changeLanguage(lang: Languages): Observable<Languages | undefined> {
        if (!lang) {
            return of(undefined);
        }

        return this.translocoService.load(isoLanguages[lang] || lang).pipe(
            map(() => {
                this.translocoService.setActiveLang(isoLanguages[lang] || lang);
                return (isoLanguages[lang] as Languages) || lang;
            }),
        );
    }

    translate(key: string | FieldTranslation, params?: HashMap, lang?: string | Languages): string {
        if (typeof key === 'string') {
            return this.translocoService.translate(key, params, lang);
        }

        const translation = lang && lang in key ? key[lang as keyof FieldTranslation] : key.default;

        return typeof translation === 'string' ? translation : key.default;
    }
}
