export enum FlooriFontFamily {
    inter = 'inter',
    roboto = 'roboto',
    sans = 'sans-serif',
    avenir = 'avenir-next',
}

export interface FlooriFont {
    family: FlooriFontFamily;
    url: string;
}
