import { inject, Injectable } from '@angular/core';
import { BaseEffects } from '@floori-web/utils/abstract';
import { createEffect, ofType } from '@ngrx/effects';
import { switchMap } from 'rxjs';
import { FLOORI_STORAGE_PROVIDER, LocalStorageKeys, StorageProvider } from '@floori-web/models';
import { visitorActions } from '../actions/visitor.actions';

@Injectable()
export class VisitorEffects extends BaseEffects {
    private readonly storage = inject<StorageProvider>(FLOORI_STORAGE_PROVIDER);

    private readonly setVisitorId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(visitorActions.setVisitorId),
            switchMap(({ visitorId }) => {
                if (!visitorId) {
                    return this.noDispatch;
                }
                return this.storage.set<string>(LocalStorageKeys.visitorId, visitorId).pipe(
                    switchMap(result => {
                        if (result) {
                            return [visitorActions.writeVisitorId({ visitorId })];
                        }

                        return this.noDispatch;
                    }),
                );
            }),
        ),
    );
}
