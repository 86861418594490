import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
// Cannot change import order - fix causes app to throw error and don't load
// eslint-disable-next-line import/order
import { CONFIG_FEATURE_KEY, ConfigEffects, ConfigFacade, configReducer } from './store';
import { EffectsModule } from '@ngrx/effects';
import { ToolsModule } from '@floori-web/tools';
import { FLOORI_CONFIG_PROVIDER } from '@floori-web/models';

@NgModule({
    imports: [
        ToolsModule,
        StoreModule.forFeature(CONFIG_FEATURE_KEY, configReducer),
        EffectsModule.forFeature([ConfigEffects]),
    ],
    providers: [
        {
            provide: FLOORI_CONFIG_PROVIDER,
            useClass: ConfigFacade,
        },
    ],
})
export class FlooriWebConfigModule {}
