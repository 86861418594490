export enum ProductStandardField {
    sku = 'sku',
    ean = 'ean',
    externalId = 'externalId',
    manufacturer = 'manufacturer',
    supplier = 'supplier',
    type = 'type',
    description = 'description',
}

export const productStandardFieldOrder: { [key: string]: number } = {
    [ProductStandardField.description]: 0,
    [ProductStandardField.sku]: 1,
    [ProductStandardField.ean]: 2,
    [ProductStandardField.externalId]: 3,
    [ProductStandardField.manufacturer]: 4,
    [ProductStandardField.supplier]: 5,
    [ProductStandardField.type]: 6,
};

export interface ProductCustomField {
    [key: string]: string[];
}
