import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, map, Observable } from 'rxjs';
import { NgDestroyed } from '@floori-web/utils/abstract';
import { CqrsEvent, EventsProvider } from '../models';

class InitEvent extends CqrsEvent {}

@Injectable()
export class EventsService extends NgDestroyed implements EventsProvider {
    private readonly messageDispatcher = new BehaviorSubject<CqrsEvent>(new InitEvent());
    private readonly messageProxy$: Observable<CqrsEvent> = this.messageDispatcher.asObservable();

    send<T extends CqrsEvent>(event: T): void {
        this.messageDispatcher.next(event);
    }

    listen<T extends CqrsEvent>(type: new (...args: unknown[]) => T): Observable<T> {
        return this.messageProxy$.pipe(
            filter(ev => ev instanceof type),
            map(ev => ev as T),
        );
    }
}
