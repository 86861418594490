import { createReducer, on } from '@ngrx/store';
import * as SplashActions from '../actions/splash.actions';
import { initialSplashState, SplashState } from '../state';

const changeSplashVisibility = on(
    SplashActions.changeSplashVisibility,
    (state: SplashState, { visible, logoUrl }) => {
        const hasLogo = !!state.logoUrl;
        const newState = { ...state, visible };
        if (!hasLogo) {
            newState.logoUrl = logoUrl;
        }
        return newState;
    },
);

export const splashReducer = createReducer(initialSplashState, changeSplashVisibility);
